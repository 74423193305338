import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './Locations.scss';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../store/actions";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"

class Locations extends Component {

    constructor(props) {
        super(props);
        this.perPage = 5;
    }
    state = {
        location: false,
    }

    listLocations() {
        let comp = [];
        let all = [];
        if (this.props.locations) {
            this.props.locations.forEach((location,key) => {

                comp.push(this.renderLocation(location));
                if (key%this.perPage === 0 && key !== 0) {
                    all.push(<div className={'location-slide'} key={location.id}>
                        {comp}
                    </div>)
                    comp = [];
                }
            })
        }
        if(comp.length) {
            all.push(<div className={'location-slide'} key={'last'}>
                {comp}
            </div>)
        }
        return all;
    }
    selectLocation(location) {
        window.scrollTo({ top: 0, behavior: 'smooth' });// scroll to top;
        this.setState({
            ...this.state,
            location:location
        })
    }
    renderLocation(location) {
        return (<div className={'location'} onClick={this.selectLocation.bind(this,location)} key={location.id}>
            <div className="name">{location.name} </div>
            {location.legal_address && <div className={'address'}>{location.legal_address}</div>  }
        </div>)
    }

    confirmLocation () {

        this.props.setLocation(this.state.location);
        this.setState({
            ...this.state,
            location: false
        })
    }

    render() {

        if (this.state.location) {
          return (
              <div className={'locations'}>
                  <div className="container">
                      <div className="details">
                          <span className="title">{this.props.t("Selected location")}:</span>
                          {this.renderLocation(this.state.location)}
                      </div>
                      <button className={'button'} onClick={this.confirmLocation.bind(this)}>{this.props.t("Select")}</button>
                      <NavLink className={'button danger'} to={'#'} onClick={this.selectLocation.bind(this,false)}>{this.props.t("Cancel")}</NavLink>
                  </div>
              </div>
          )
        }
        if (this.props.locations) {

            return (
                <div className={'locations'}>
                    <div className="container">
                        <h2>{this.props.t("Select location")}</h2>
                        <Carousel>
                            {this.listLocations()}
                        </Carousel>
                    </div>

                </div>
            );
        } else {
            return (<></>);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.application.locations_list,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRecentPlayers: (page) => dispatch(storeActions.getRecentPlayers(page)),
        setLocation: (location) => dispatch(storeActions.setLocation(location))
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations)));
