import {put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import {druid} from "../actions/actionTypes";

export function* scanDocument(action) {
    const axios = ClientAPI.getInstance();
    try {
        let uploadForm = new FormData();

        uploadForm.append("files[]", action.files[0]);
        switch (action.scanType) {
            case 'access':
                uploadForm.append("type", 'log-in')
                break;
            case 'blacklist':
                uploadForm.append("type", 'blacklist')
                break;
            case 'exclusion':
                uploadForm.append("type", 'self-exclusion')
                break;
            default:

                break;
        }

        if (action.opt) {
            uploadForm.append("opts", action.opt);
        }

        const response = yield axios({
            url: '/api/documents/scan',
            method: 'post',
            data: uploadForm,
            headers: {
                "Content-Type": "multipart/form",
            }
        });

        if (!response) {
            throw new Error(`[ERROR] DRUID SCAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] DRUID SCAN  response has no 'result' property`);
        }

        if (response.result.hasOwnProperty('errors')) {
            // TODO: remove debug
            // let el = document.getElementById('root');
            // let div = document.createElement("div");
            // div.style = "position: absolute;width: 100vw; background: white; color: black;top:0;left:0; overflow: auto";
            // div.innerText = JSON.stringify(response.result.errors, undefined, 4);
            // el.appendChild(div);

            yield put(actions.scanComplete(false));
        } else {
            yield put(actions.scanComplete(response.result));
        }


    } catch (error) {
        yield put(actions.scanComplete(false));
        return;
    }
}


export function* requestDocumentsMaxFileSizeSaga(action) {

    const maxFileSize = yield ClientAPI.getStore().getState().druid.documentsMaxFileSize;
    if (maxFileSize) {
        return false;
    }
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: '/api/documents/max-upload-size',
            method: 'get',
        });

        if (!response) {
            throw new Error(`[ERROR] Documents max file size response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Documents max file size response has no 'result' property`);
        }

        yield put(actions.receivedMaxFileSize(response.result?.maxSize));
    } catch (error) {
        console.log(error);
        return;
    }
}

export default function* watchDruidSaga() {
    yield takeEvery(druid.SCAN_DOCUMENT, scanDocument);
    yield takeEvery(druid.MAX_FILE_SIZE, requestDocumentsMaxFileSizeSaga);
}